.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;

  .text {
    color: var(--black-color);
    user-select: none;
    margin: 0 var(--gap-slt);

    &.active {
      font-weight: 700;
      color: var(--blue-color);
    }

     &:hover, &:focus {
       outline: none;
       cursor: pointer;
       color: var(--blue-color);
     }
  }

  .arrow {
    $size: 20px;
    fill: var(--black-color);
    cursor: pointer;

    width: $size;
    height: $size;

    &:hover, &:focus {
      outline: none;
      fill: var(--blue-color);
    }
  }
}
