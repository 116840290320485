.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .breadcrumbText {
    $gap: var(--gap-small);
    white-space: nowrap;

    &:not(:first-child) {
      margin-left: $gap;
      white-space: break-spaces;
    }

    &:not(:first-child):before {
      content: '/';
      font-weight: 700;
      margin-right: $gap;
    }
  }
}
