.inputBox {
  position: sticky;
  top: 0;
  z-index: 10;
  background: var(--white-color);
  margin-bottom: var(--gap-mdp);
  padding: var(--gap-slt) 0;

  .search {
    width: 100%;
  }
}

.categoryMenu {
  @import './src/styles/scrollbar';

  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  overflow-y: auto;
  background: var(--white-color);
  padding: var(--gap-mdp) var(--gap-small);
  box-shadow: 12px 4px 31px 1px rgba(3, 60, 80, 0.07) inset;
}

.header {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  flex: 0 0 auto;
  top: 0;
  width: 100%;
  height: var(--header-height);
  padding: var(--gap-small) var(--default-gap);
  z-index: 600;

  .menuIcon {
    height: 25px;
  }

  .navLink {
    color: var(--white-color);
  }

  .headerLogo {
    margin-left: var(--gap-small);
  }

  .headerMainIconGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }

  .headerIconGroup {
    .headerSearch {
      display: inline-block;
    }
  }
}
