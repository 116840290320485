.prompt {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--blue-color);
  padding: var(--gap-slt);
  border-radius: var(--sm-border-radius);
  margin: var(--gap-small) 0;

  .promptIcon {
    fill: var(--blue-color);
    margin-right: var(--gap-slt);
  }
}
