.modalReview {
  min-width: 450px;

  .title {
    margin-bottom: var(--gap-small);
  }

  .modalButtons {
    .button {
      margin-top: var(--gap-small);

      &:not(:first-child) {
        margin-left: var(--gap-small);
      }
    }
  }
}
