.inputBox {
  width: 100%;
  background: var(--white-color);
  padding: var(--gap-slt) 0;
  position: sticky;
  top: 0;
  z-index: 20;

  .search {
    width: 100%;
    margin: var(--gap-mdp) 0;
  }
}

.subcategoryLink {
  font-size: var(--text-md);

  &:not(:last-child) {
    margin-bottom: var(--default-gap);
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  flex: 0 0 auto;
  padding: var(--gap-small);
  height: var(--header-height);
  z-index: 790;

  &[data-dark='true'] {
    svg {
      fill: var(--black-color);
    }
  }

  .logo {
    height: 40px;
    margin-left: 20px;
  }

  .searchIcon {
    margin-left: auto;
  }
}

.iconGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: var(--gap-small) 0;

  svg {
    fill: var(--black-color);
  }
}
