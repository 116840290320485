.selectOptionGroup {
  list-style-type: none;

  .label {
    font-weight: 700;
  }

  .selectOptions {
    padding: var(--gap-slt);
  }
}
