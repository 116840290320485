.catalogContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  .pagination {
    margin-top: var(--default-gap);
    align-self: center;
  }
}