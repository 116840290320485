.priceOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
  margin: var(--gap-slt) 0;

  .priceTitle {
    margin-right: var(--default-gap);
  }

  .highlight {
    font-weight: 700;
  }
}
