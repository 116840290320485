.mobileChosenItem {
  display: flex;
  flex-direction: column;
  padding: var(--gap-slt);

  .image {
    max-width: 250px;
    width: 100%;
    border-radius: var(--border-radius);
    align-self: center;
    margin: var(--gap-mdp) 0;
  }

  .head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: var(--gap-mdp) 0;
    position: relative;

    .title {
      color: var(--black-color);
      margin-bottom: var(--gap-mdp);
      transition: var(--hover-anim-dur);

      &:hover {
        color: var(--blue-color);
      }
    }

    .label {
      margin-top: var(--gap-slt);
    }
  }

  .itemDescription {
    .characteristics {
      list-style-type: none;

      .characteristic {
        margin: var(--gap-slt) 0;
      }
    }

    .price {
      font-weight: 700;
      color: var(--blue-color);
      margin: var(--gap-small) 0;
    }

    .buttonDelete {
      width: 100%;
    }

    .itemOptions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      margin: var(--gap-mdp) 0;

      .addButton, .subtractButton {
        background: var(--blue-color);
      }

      .addIcon, .subtractIcon {
        fill: var(--blue-color);
      }
    }
  }
}
