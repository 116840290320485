.subcategoryCol {
  min-width: 250px;
  padding: 0 var(--gap-small);

  &:not(:last-child) {
    margin-bottom: var(--gap-small);
  }

  .subcategoryLink {
    color: var(--black-color);

    &.highlight {
      font-weight: 700;
    }
  }
}
