.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: var(--main-page-padding);

  .authForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    width: 100%;
  }

  .field, .button {
    width: 100%;
    margin: var(--gap-slt) 0;
    max-width: 250px;
  }

  .link {
    margin: var(--gap-small) 0;
  }
}
