.searchMenu {
  max-width: 250px;
  background: var(--white-color);
  box-shadow: 10px 13px 38px -10px #00000040;
  padding: var(--gap-small);
  min-width: 250px;
  border-radius: var(--border-radius);

  .results {
    @import './src/styles/scrollbar';
    display: flex;
    flex-direction: column;
    padding: var(--gap-slt);
    margin-top: var(--gap-slt);
    max-height: 250px;
    overflow-y: auto;

    .resultLink {
      &:not(:first-child) {
        margin-top: var(--gap-small);
      }
    }

    .showMore {
      font-weight: 600;
      text-align: center;
    }
  }
}
