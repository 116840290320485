.icon {
  &[data-size='slt'] {
    height: 15px;
  }

  &[data-size='small'] {
    height: 20px;
  }

  &[data-size='medium'] {
    height: 25px;
  }

  &[data-size='large'] {
    height: 30px;
  }
}
