.imagesSlider {
  display: flex;
  flex-direction: column;
  max-width: 350px;

  .imageSliderBlock {
    margin-top: var(--gap-slt);
    width: 100%;
  }

  .imageButton {
    outline: none;
    cursor: pointer;
    transition: var(--hover-anim-dur);

    &:focus {
      outline: none;
      transform: scale(0.95);
    }
  }

  .largeImage {
    object-fit: cover;
    border-radius: var(--border-radius);
    width: 100%;
    max-width: 350px;
    height: 350px;
  }
}

.imageSliderBlock {
  margin-top: var(--gap-small);
  object-fit: cover;

  .slide {
    --size: 100px;

    width: var(--size);
    height: var(--size);
    border-radius: var(--border-radius);
    position: relative;
    overflow: hidden;

    .image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      transition: var(--hover-anim-dur);

      &:hover, &:focus, &.active {
        outline: none;
        cursor: pointer;
        transform: scale(.9);
      }

      &.active {
        border: 4px solid var(--blue-color);
      }
    }
  }
}
