.rate {
  display: flex;
  flex-direction: row;
  place-items: center;

  .appraisal {
    font-weight: 700;
    margin-right: .5rem;
  }
}

.star {
  &[data-size='small'] {
    width: 16px;
    height: 16px;
  }

  &[data-size='large'] {
    width: 24px;
    height: 24px;
  }
}
