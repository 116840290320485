.navigation {
  display: flex;

  &[data-orientation='vertical'] {
    flex-direction: column;

    .navigationLink {
      margin: var(--gap-slt) 0;
    }
  }

  &[data-orientation='horizontal'] {
    flex-direction: row;

    .navigationLink {
      margin: 0 var(--gap-slt);
    }
  }
}
