.qualitySection {
  width: 100%;

  .linkGroup {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;

    .qualityLinkBlock {
      margin: var(--default-gap);
      width: 170px;
      height: 220px;
    }
  }
}
