%font {
  white-space: pre-line;
}

.titleH1 {
  @extend %font;
  font-size: var(--text-xxxl);
}

.titleH2 {
  @extend %font;
  font-size: var(--text-xxl);
}

.titleH3 {
  @extend %font;
  font-size: var(--text-xl);
}

.titleH4 {
  @extend %font;
  font-size: var(--text-lg);
}

.titleH5 {
  @extend %font;
  font-size: var(--text-md);
}

.titleH6 {
  @extend %font;
  font-size: var(--text-sm);
}

.subtitle {
  @extend %font;
  font-size: var(--text-xs);
}
.subtitle2 {
  @extend %font;
  font-size: var(--text-xss);
}

.textBody {
  @extend %font;
  font-size: var(--text-standard);
}

.bgTitle {
  @extend %font;
  font-size: var(--text-bg);
}

.bgtTitle {
  @extend %font;
  font-size: var(--text-bgg);
}
