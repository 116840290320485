.iconButton {
  background: none;
  outline: none;
  border: none;
  height: 25px;
  cursor: pointer;
  padding: 0 !important;

  .icon {
    height: 100%;
  }
}
