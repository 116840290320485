@import 'breakpoints';

:root {
  --text-standard: 1em;
  --text-scale-ratio: .825;
  --text-xss: calc(var(--text-standard) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xs: calc(var(--text-standard) * var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) / var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) / var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) / var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) / var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) / var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) / var(--text-scale-ratio));
  --text-bg: calc(var(--text-xxxl) / var(--text-scale-ratio) / var(--text-scale-ratio));
  --text-bgg: calc(var(--text-xxxl) / var(--text-scale-ratio) / var(--text-scale-ratio) * 1.75);
}

@media screen and (max-width: map-get($breakpoint, 'lg')) {
  :root {
    --text-scale-ratio: .85;
  }
}

@media screen and (max-width: map-get($breakpoint, 'md')) {
  :root {
    --text-scale-ratio: .875;
  }
}

@media screen and (max-width: map-get($breakpoint, 'sm')) {
  :root {
    --text-scale-ratio: .9;
  }
}
