@import './src/styles/breakpoints';

%focus {
  background: var(--blue-color);
  border-color: var(--blue-color);
}

.dotsGroup {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: var(--gap-mdp);
  top: 50%;
  transform: translate(0, -50%);

  .dot {
    $size: 15px;
    width: $size;
    height: $size;
    border-radius: 25px;
    border: 2px solid var(--white-color);
    margin: 5px;
    cursor: pointer;
    transition: .25s;
    outline: none;

    &.active {
      background: var(--white-color);
    }

    &:hover {
      @extend %focus;
    }

    &:focus {
      @extend %focus;
      outline-offset: 0;
      outline: none;
    }
  }
}

@media screen and (max-width: map-get($breakpoint, 'sm')) {
  .dotsGroup {
    flex-direction: row;
    bottom: 0; top: auto;
  }
}
