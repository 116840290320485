.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: var(--page-med-gap);
  min-height: 70vh;
  position: relative;
  z-index: 1;

  .description, .subtitle, .title, .button {
    z-index: 3;
  }

  .subtitle {
    text-transform: uppercase;
    font-weight: 700;
  }

  .title {
    margin: var(--gap-mdp) 0;
    font-weight: 700;
    background: linear-gradient(90deg, #92FE9D 0%, #00C9FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .button {
    margin-top: var(--default-gap);
  }

  .image {
    position: absolute;
    bottom: 0;
    height: 105%;
    right: 15%;
    z-index: 2;
  }
}
