@import './src/styles/breakpoints';

.main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  padding: var(--main-page-padding);
}

@media screen and (max-width: map-get($breakpoint, 'md')) {
  .icon {
    margin-bottom: var(--default-gap);
  }
}
