.admin {
  flex: 1 0 auto;
  padding: var(--main-page-padding);

  .dashboard {
    display: flex;
    flex-flow: column wrap;
    margin-top: var(--gap-mdp);

    .nav {
      margin-bottom: var(--gap-small);
      flex-wrap: wrap;
    }

    .workspace {
      flex: 1;
    }
  }
}
