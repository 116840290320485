@import './src/styles/breakpoints';

.policyOptions {
  display: flex;
  flex-flow: row wrap;

  .policyType {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin: var(--gap-slt) 0;

    &:not(:first-child) {
      margin-left: var(--gap-small);
    }

    .policyOption {
      margin-left: var(--gap-slt);
    }
  }
}

.policyBlock {
  margin: var(--gap-small) 0;

  .textBlock {
    margin: var(--gap-slt) 0;
  }

  .policyButton {
    margin: var(--gap-slt) 0;
  }
}

@media screen and (max-width: 768px) {
  .policyOptions {
    .policyType {
      margin: var(--gap-mdp) 0;
      margin-left: 0 !important;

      .policyOption {
        margin-top: var(--gap-slt);
      }
    }
  }

  .sectionTitle {
    margin: var(--gap-mdp) 0;
  }
}
