@import '../../styles/breakpoints';

.overlay {
  --padding: var(--gap-small);

  position: fixed;
  z-index: 800;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  padding: var(--padding);

  .close {
    position: absolute;
    top: var(--padding);
    right: var(--padding);
  }
}

.scaleImage {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  height: 100%;
  width: 100%;
  padding: var(--default-gap);

  .mainImage {
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin-left: auto;
  }
}

@media screen and (max-width: map-get($breakpoint, 'sm')) {

}
