@import './src/styles/breakpoints';

.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: var(--default-gap);
  grid-template-areas: 'order payment';
}

.delivery {
  grid-area: order;
}

.payment {
  grid-area: payment;
}

.sectionTitle {
  margin: var(--gap-mdp) 0;
}

.itemsGroup {
  @import './src/styles/scrollbar';

  max-height: 450px;
  overflow: auto;
  padding-right: var(--gap-mdp);
}

.item {
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: var(--gap-mdp);
  }
}

.orderingOptions {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.orderingType {
  display: flex;
  flex-direction: row;

  .orderingOption {
    margin-left: var(--gap-small);
  }
}

.fields {
  display: flex;
  flex-flow: row wrap;

  .fieldGroup {
    display: flex;
    flex-direction: column;
    min-width: 250px;

    &:not(:last-child) {
      margin-right: var(--gap-small);
    }

    .field {
      margin: var(--gap-slt) 0;
    }
  }
}

.selectField {
  min-width: 250px;
  margin: var(--gap-slt) 0;
}

.payment {
  margin: 0 var(--gap-slt);

  .paymentIcons {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .paymentIcon {
      margin: var(--gap-small) 0;

      &:not(:last-child) {
        margin-right: var(--gap-small);
      }
    }
  }

  .button {
    width: 100%;
    margin-bottom: var(--gap-mdp);
  }

  .policyText {
    .highlight {
      border-bottom: 1px solid var(--blue-color);
      line-height: 2rem;
    }
  }

  .priceBlock {
    margin: var(--gap-mdp) 0;
  }

  .priceField {
    width: 100%;
    margin: var(--gap-small) 0;
  }

  .prompt {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid var(--blue-color);
    padding: var(--gap-slt);
    border-radius: var(--sm-border-radius);
    margin: var(--gap-small) 0;

    .promptIcon {
      fill: var(--blue-color);
      margin-right: var(--gap-slt);
    }
  }
}

@media screen and (max-width: map-get($breakpoint, 'lg')) {
  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    gap: var(--default-gap);
    grid-template-areas: 'order' 'payment';

    .payment {
      margin: var(--default-gap) 0;
    }

    .fields {
      .fieldGroup {
        margin: 0;
        width: 100%;
        min-width: 0;
      }
    }
    .selectField {
      width: 100%;
      min-width: 0;
    }

    .payment {
      min-width: 150px;
    }
  }
}
