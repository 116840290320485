@import './src/styles/breakpoints';

.modalButtons {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin-top: var(--gap-mdp);

  .modalButton {
    &:not(:first-child) {
      margin-left: var(--gap-small);
    }
  }
}

.modalBucketBody {
  @import './src/styles/scrollbar';
  max-height: 450px;
  overflow-y: auto;

  .modalItem {
    margin: var(--gap-small) 0;
  }
}

.breadcrumbBlock {
  margin-bottom: var(--default-gap);
}

.mainBreadcrumbText {
  font-weight: 700;
}

.itemBlock {
  display: flex;
  flex-direction: row;
  position: relative;
}

.itemMain {
  display: flex;
  flex-direction: column;
  margin: 0 calc(var(--page-med-gap) * .8);

  .title {
    width: 50%;
  }

  .priceBlock {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .label {
      margin-left: var(--gap-small);
    }
  }

  .info {
    display: grid;
    grid-template-columns: minmax(150px, 300px) 1fr;
    gap: var(--gap-mdp);
    margin-top: var(--gap-small);

    .params {
      display: flex;
      flex-direction: column;

      .param {
        margin: var(--gap-slt) 0;
      }
    }

    .description {
      max-width: 450px;

      .characteristics {
        list-style-type: none;
        margin-top: var(--gap-small);
      }

      .descriptionText {
        margin: var(--gap-mdp) 0;
      }
    }
  }

  .labels {
    display: flex;
    flex-flow: row wrap;
    --gap: var(--gap-slt);
    --column-gap: var(--gap);
    --row-gap: var(--gap);
    margin: calc(var(--row-gap) / -2) calc(var(--column-gap) / -2);

    .labelLink {
      text-decoration: none;
    }

    .label {
      transition: var(--hover-anim-dur);
      cursor: pointer;
      margin: calc(var(--row-gap) / 2) calc(var(--column-gap) / 2);

      &:hover, &:focus {
        color: var(--white-color);
        background: var(--blue-color);
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoint, 'lg')) {
  .modalButtons {
    .modalButton {
      &:not(:first-child) {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoint, 'md')) {
  .modalButtons {
    .modalButton {
      width: 100%;
      margin: var(--gap-small) 0;
    }
  }

  .itemBlock {
    justify-content: center;
  }

  .itemMain {
    margin-top: var(--default-gap);

    .title {
      width: 100%;
    }
  }
}

@media screen and (max-width: map-get($breakpoint, 'lg')) {
  .itemBlock {
    .itemMain {
      .info {
        grid-template-rows: repeat(2, auto);
        grid-template-columns: none;
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoint, 'md')) {
  .itemBlock {
    flex-direction: column;

    .itemMain {
      margin: var(--default-gap) 0 0;

      .info {
        .params {
          margin-right: 0;
          width: 100%;
        }
      }

      .description {
        margin-top: var(--default-gap);
      }
    }
  }
}
