%field {
  min-height: 45px;
  background: var(--grey-color);
  border: 1px solid var(--dark-grey-color);
  border-radius: var(--sm-border-radius);
  font-family: Arial, sans-serif;
  font-size: .875rem;
  text-indent: 12px;
  outline: none;
  transition: var(--hover-anim-dur);
}

%placeholder {
  font-size: 1em;
  font-family: Arial, sans-serif;
  color: var(--dark-grey-200-color);
  opacity: .7;
}
