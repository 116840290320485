@import '../../styles/breakpoints';

.table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  min-width: 150px;
  box-shadow: 10px 13px 38px -10px #00000040;
  border-radius: var(--border-radius);

  .tr {
    padding: var(--gap-slt);
    word-wrap: break-word;
  }

  .th {
    font-size: var(--text-standard);
  }

  .tableField {
    padding: var(--gap-slt);
    text-align: center;
    word-wrap: break-word;
  }

  .button {
    padding: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: map-get($breakpoint, 'lg')) {
  .table {
    border: 0;

    .thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    .tr {
      display: block;
      margin-bottom: .625em;
    }

    .td {
      display: block;
      text-align: right;

      &::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
