@import './src/styles/breakpoints';

.overlay {
  position: fixed;
  z-index: 800;
  width: 100%;
  height: 100%;
}

.drawer {
  $padding: var(--gap-small);
  $paddingTop: calc(var(--gap-small) * 3);

  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 350px;
  background: var(--white-color);
  padding: 0 var(--gap-slt);
  position: fixed;
  top: 0;
  z-index: 900;

  .closeIcon {
    width: min-content;
    margin-left: auto;
    padding: var(--gap-slt);

    svg {
      fill: var(--black-color);
      transition: var(--hover-anim-dur);

      &:hover {
        fill: var(--blue-color);
      }
    }
  }

  .head {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: var(--blue-color);
    width: 100%;
    min-height: 80px;
  }

  .content {
    @import './src/styles/scrollbar';

    display: flex;
    flex: 1;
    align-self: flex-end;
    flex-direction: column;
    padding: 0 $padding $padding $padding;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
}

@media screen and (max-width: map-get($breakpoint, 'md')) {
  .drawer {
    width: 100%;
  }
}
