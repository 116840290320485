@import './src/styles/breakpoints';

.basketContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .basketItems {
    margin-right: var(--page-gap);
    flex: 1;

    .item {
      margin: var(--gap-mdp) 0;
    }
  }
}

.price {
  width: 250px;

  .priceGroup {
    margin: var(--default-gap) 0;
  }

  .button {
    width: 100%;
  }
}

@media screen and (max-width: map-get($breakpoint, 'lg')) {
  .basketContainer {
    justify-content: center;
    flex-direction: column-reverse;

    .basketItems {
      margin-right: 0;
      margin-bottom: var(--gap-mdp);
    }
  }

  .price {
    width: 100%;
  }
}
