@import '../../../styles/breakpoints';

.chosenItem {
  display: flex;
  flex-direction: row;
  position: relative;

  .deleteIcon {
    position: absolute;
    top: 0;
    right: 0;

    svg {
      transition: var(--hover-anim-dur);
      fill: var(--black-color);
    }

    &:hover, &:focus {
      svg {
        fill: var(--blue-color);
      }
    }
  }

  .image {
    $size: 100px;

    object-fit: cover;
    width: $size;
    height: $size;
    border-radius: var(--border-radius);
  }

  .itemBody {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--gap-small);
    margin-left: var(--gap-mdp);
    flex: 1;

    .head {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: var(--gap-slt);

      .title {
        max-width: 150px;
        margin-bottom: var(--gap-slt);
        color: var(--black-color);
        transition: var(--hover-anim-dur);

        &:hover {
          color: var(--blue-color);
        }
      }

      .label {
        margin-top: var(--gap-slt);
      }
    }

    .itemDescription {
      display: grid;
      grid-template-areas: 'description' 'count' 'price';
      gap: var(--gap-slt);
      align-items: center;
      grid-template-rows: repeat(3, min-content);

      @supports ( not (grid-template-columns: repeat(3, min-content))) {
        grid-template-rows: repeat(3, 1fr);
      }

      .characteristics {
        list-style-type: none;
        grid-area: description;

        .characteristic {
          margin: 6px 0;
        }
      }

      .itemOptions {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: var(--gap-mdp);
        grid-area: count;

        .option {
          font-weight: 700;
          margin: 0 var(--gap-small);
        }

        .addIcon, .subtractIcon {
          svg {
            fill: var(--black-color);
            transition: var(--hover-anim-dur);
          }

          &:hover, &:focus {
            svg {
              fill: var(--blue-color);
            }
          }
        }
      }

      .price {
        color: var(--blue-color);
        font-weight: 700;
        margin-left: var(--gap-mdp);
        justify-self: end;
        grid-area: price;
      }
    }
  }
}
