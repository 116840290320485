@import '../../styles/breakpoints';

.modalPurchase {
  @import '../../styles/scrollbar';

  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 450px;
  overflow-y: auto;
  padding: 0 10px;

  .characteristics {
    list-style-type: none;
  }

  .field {
    margin: var(--gap-slt) 0;
  }

  .param {
    width: 100%;
  }

  .priceBlock {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: var(--gap-small) 0;

    .discountLabel {
      margin-left: var(--gap-small);
    }
  }
}

@media screen and (max-width: map-get($breakpoint, 'md')) {
  .modal {
    @import '../../styles/scrollbar';

    padding: var(--gap-small);
    overflow-y: auto;
  }

  .modalPurchase {
    max-height: initial;
  }

  .itemImages {
    width: 100%;
  }
}
