@import './breakpoints';

@mixin cardGroup($mode) {
  --card-min-width: 200px;
  display: grid;
  grid-template-columns: repeat($mode, minmax(var(--card-min-width), 1fr));

  @media screen and (max-width: map-get($breakpoint, 'sm')) {
    --card-min-width: 130px;
  }
}

@mixin card {
  padding: var(--gap-small);
  background: var(--white-color);
  box-shadow: 10px 13px 38px -10px #00000040;
  border-radius: var(--border-radius);
}