.reviewsGroup {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--gap-mdp);

  .review {
    width: 100%;
    min-width: 100%;
  }
}
