@import '../../styles/breakpoints';
@import '../../styles/mixins';

.itemGroup {
  @include cardGroup(auto-fill);
  display: grid;
  grid-auto-flow: row dense;
  justify-items: center;
  gap: var(--default-gap);

  .card {
    width: 100%;
  }
}
