%primary {
  background: var(--blue-color);
  color: var(--white-color);
}

%secondary {
  border: 2px solid var(--blue-color);
  color: var(--blue-color);
}

%third {
  background: var(--blue-color);
  color: var(--white-color);
}

%fourth {
  background: var(--white-color);
  color: var(--blue-color);
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: .875em;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  padding: 10px 60px;
  min-height: 45px;
  transition: var(--hover-anim-dur);
  cursor: pointer;
  border-radius: var(--sm-border-radius);
  outline: none;
  border: none;
  background: none;

  &.primaryButtonTheme {
    @extend %primary;
    border: 2px solid transparent;

    &:hover, &:focus {
      @extend %secondary;
      background: none;
    }
  }

  &.secondaryButtonTheme {
    @extend %secondary;

    &:hover, &:focus {
      @extend %primary;
    }
  }

  &.thirdButtonTheme {
    @extend %third;

    &:hover, &:focus {
      @extend %fourth;
    }
  }

  &.textButtonTheme {
    font-size: 1em;
    text-transform: none;
    padding: 0;

    &:hover, &:focus {
      color: var(--blue-color);
    }
  }
}