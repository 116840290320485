@import './src/styles/breakpoints';

.reviewsBlock {
  margin: var(--gap-mdp) 0;
}

.reviewsGroup {
  width: 100%;
  margin-bottom: var(--gap-small);
}

.reviewButton {
  margin-top: var(--gap-mdp);
}

.reviewFields {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  .reviewField {
    min-width: 250px;
    margin: var(--gap-slt) 0;
  }
}

.starField {
  margin: var(--gap-mdp) 0;
}

.reviewTextField {
  width: 100%;
  min-height: 250px;
}

@media screen and (max-width: map-get($breakpoint, 'lg')) {
  .reviewFields {
    .reviewField {
      min-width: 100%;
      width: 100%;
      margin: var(--gap-mdp) 0;
    }
  }
}
