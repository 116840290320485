@import '../../styles/breakpoints';

.categoryGroup {
  margin: var(--gap-slt) 0;
  cursor: pointer;
  transition: var(--hover-anim-dur);

  &.active, &:hover {
    color: var(--blue-color);
  }
}

@media screen and (max-width: map-get($breakpoint, 'md')) {
  .categoryGroup {
    margin: var(--gap-small) 0;
  }
}
