@import './src/styles/field';

.textarea {
  @extend %field;
  min-height: 90px;
  padding: var(--gap-slt);

  &::placeholder {
    @extend %placeholder;
  }

  &:hover, &:focus {
    border-color: var(--blue-color);
  }
}
