.link {
  text-decoration: none;
  font-size: var(--text-lg);
  font-weight: 700;
  color: var(--white-color);
  position: relative;
  transition: var(--hover-anim-dur);

  &[data-size='small'] {
    font-size: var(--text-xs);
  }

  &[data-size='medium'] {
    font-size: var(--text-standard);
  }

  &[data-size='large'] {
    font-size: var(--text-lg);
  }

  &::after {
    content: '';
    width: 125%;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 202 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='7' width='199' height='4' fill='%2300C9FF'/%3E%3Cpath d='M190 1.9L192 0L202 9.5L192 19L190 17.1L198 9.5L190 1.9Z' fill='%2300C9FF'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    position: absolute;
    left: 0; bottom: -20px;
    height: 15px;
  }

  &:hover, &:focus {
    outline: none;
    color: var(--blue-color);
  }
}

.primaryLinkTheme {
  color: var(--white-color);
}

.secondaryLinkTheme {
  color: var(--black-color);
}

.defaultLinkTheme {
  color: #000000;
  font-weight: 500;

  &::after {
    display: none;
  }
}
