@import './src/styles/breakpoints';

.mainBreadcrumbText {
  font-weight: 700;
}

.filterTitle {
  margin-top: var(--gap-small);
}

.items {
  display: flex;
  flex-direction: row;
  padding: var(--default-gap) 0;

  .filterGroup {
    display: flex;
    flex-direction: column;
    max-width: 250px;

    .filter {
      width: 100%;
      margin-bottom: var(--gap-small);
    }

    .priceText {
      margin-bottom: var(--gap-slt);
    }

    .priceFilter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .priceField {
        width: 47%;
      }
    }
  }

  .itemsContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
  }

  .itemGroup {
    margin-left: var(--default-gap);
  }
}

@media screen and (max-width: map-get($breakpoint, 'sm')) {
    .items {
      flex-direction: column;

      .filterGroup {
        margin-right: 0;
      }

      .itemGroup {
        margin-left: 0;
        margin-top: var(--default-gap);
        justify-content: center;
      }
    }
}
