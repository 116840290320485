@import './src/styles/field';

.selectField {
  @extend %field;
  $size: 12px;

  display: flex;
  align-items: center;
  text-indent: $size;
  cursor: pointer;
  position: relative;

  .placeholder {
    @extend %placeholder;
    user-select: none;
  }

  .arrow {
    height: 14px;
    position: absolute;
    right: $size;
    fill: var(--dark-grey-color);
    transition: var(--hover-anim-dur);
  }

  &:hover, &:focus {
    border-color: var(--blue-color);

    .arrow {
      fill: var(--blue-color);
    }
  }
}
