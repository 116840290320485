.errorBlock {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  word-wrap: break-word;
  padding: var(--gap-slt);
  background: var(--white-color);
  border: 1px solid var(--red-color);
  border-radius: var(--sm-border-radius);
  box-shadow: 10px 13px 38px -10px #00000040;
  z-index: 900;

  .icon {
    fill: var(--red-color);
    $size: 30px;

    width: $size;
    height: $size;
  }
}

.error {
  font-size: .875em;
  width: 100%;
}
