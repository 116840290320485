.loadingLine {
  width: 100%;
  height: 2px;
  color: transparent;
  background: linear-gradient(100deg, var(--blue-color) 5%, #f6f7f8 50%, var(--grey-color-100) 70%);
  border-radius: var(--border-radius);
  background-size: 400%;
  animation: loadingLine .75s ease-in-out infinite;
}

@keyframes loadingLine {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
