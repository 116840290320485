.sliderTrack {
  display: flex;
  flex-direction: row;
  transform: translateX(0);
  transition: 1.5s;

  &[data-position='horizontal'] > div:not(:last-child) {
    margin-right: var(--gap);
  }

  &[data-position='vertical'] > div:not(:last-child) {
    margin-bottom: var(--gap);
  }
}


