.linkGroup {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;

  .qualityLinkBlock {
    margin: var(--default-gap);
  }
}

.point {
  margin: var(--gap-mdp) 0;
}
