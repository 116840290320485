@import 'breakpoints';

:root {
  --gap-scale: 1;

  --default-gap: calc(40px);
  --gap-mdp: calc(30px);
  --gap-small: calc(20px);
  --gap-slt: calc(10px);
  --page-med-gap: calc(90px * var(--gap-scale));
  --page-gap: calc(120px * var(--gap-scale));

  --main-page-padding: calc(var(--header-height) * 1.5) calc(var(--page-gap) * var(--gap-scale)) calc(var(--page-gap) * var(--gap-scale)) calc(var(--page-gap) * var(--gap-scale));

  --black-color: #0b141a;
  --blue-color: #00C9FF;
  --red-color: #ff3b3b;
  --green-color: #92FE9D;
  --white-color: #ffffff;
  --grey-color: #F8F8F8;
  --grey-color-100: #f3f3f3;
  --dark-grey-color: #e0dfdf;
  --dark-grey-200-color: #a5a5a5;
  --light-blue-color: #DEF8FF;

  --black-color-r: 11;
  --black-color-g: 20;
  --black-color-b: 26;

  --lg-border-radius: 21px;
  --border-radius: 12px;
  --sm-border-radius: calc(var(--border-radius) / 2);

  --header-height: 80px;

  --hover-anim-dur: .25s;
}

@media screen and (max-width: map-get($breakpoint, 'sm')) {
  :root {
    --gap-scale: .5;
  }
}
