@import './src/styles/breakpoints';

.sectionTitle {
  margin: var(--gap-mdp) 0;
}

.userData {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: var(--gap-small);

  .fieldGroup {
    display: flex;
    flex-direction: column;
    margin: 0 var(--gap-slt);

    .field {
      margin: var(--gap-slt) 0;
      min-width: 250px;
    }
  }
}

.button {
  min-width: 250px;
}

.ordersBlock {
  display: flex;
  flex-direction: column;
  place-items: center;
  margin-top: var(--gap-mdp);
  width: 100%;

  .orders {
    width: 50%;
    margin-bottom: var(--gap-small);
  }
}

@media screen and (max-width: map-get($breakpoint, 'sm')) {
  .fieldGroup {
    margin: var(--default-gap) 0 !important;
    width: 100%;

    .field {
      min-width: 100% !important;
      width: 100%;
    }
  }

  .button {
    width: 100%;
  }
}
