.paymentIcons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 250px;
  margin-top: var(--gap-mdp);

  .paymentIcon {
    path {
      fill: white;
    }

    margin: var(--gap-slt) 0;

    &:not(:last-child) {
      margin-right: var(--gap-slt);
    }
  }
}
