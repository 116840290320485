@import '../../../styles/breakpoints';

.modal {
  @import '../../../styles/scrollbar';
  min-width: 450px;

  .modalBody {
    max-height: 350px;
    overflow-y: auto;
    @import '../../../styles/scrollbar';
  }

  .ul {
    list-style-type: none;
    width: 100%;
    margin-bottom: var(--gap-small);

    .li {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: var(--gap-slt);
      }

      .liTitle {
        margin-right: var(--gap-small);
      }

      .liText {
        max-width: 50%;
        text-align: end;
      }
    }
  }

  .item {
    margin: var(--gap-small) 0;
  }
}

@media screen and (max-width: map-get($breakpoint, 'md')) {
  .modal {
    overflow: auto;
    min-width: initial;

    .modalBody {
      max-height: initial;
    }
  }
}