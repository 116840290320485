@import '../../styles/breakpoints';

.itemCard {
  cursor: pointer;
  transition: var(--hover-anim-dur);
  position: relative;
  max-width: 200px;
  text-decoration: none;
  color: var(--black-color);

  &:hover {
    color: var(--blue-color);
  }

  &:focus {
    outline: none;
  }

  &:hover, &:focus {
    .buttonFastPurchase {
      display: flex;
      width: 100%;
      padding: 0;
    }
  }

  .image {
    border-radius: var(--lg-border-radius);
    width: 100%;
    height: 255px;
    position: relative;
    object-fit: cover;
  }

  .title {
    margin: var(--gap-small) 0;
    word-break: break-word;
  }

  .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    font-size: var(--text-xs);
    width: 33%;
    height: 35px;
    position: absolute;
    border-radius: var(--sm-border-radius) var(--lg-border-radius) var(--sm-border-radius) var(--sm-border-radius);
    top: 0;
    right: 0;
  }

  .buttonFastPurchase {
    position: absolute;
    display: none;
    border-radius: 0;
    z-index: 500;
    left: 0;
    top: 40%;
  }
}

@media screen and (max-width: map-get($breakpoint, 'sm')) {
  .itemCard {
    .image {
      height: 200px;
    }
  }
}
