&::-webkit-scrollbar {
  background-color: var(--grey-color-100);
  width: 8px;
  border-radius: var(--border-radius);
}

&::-webkit-scrollbar-thumb {
  background: var(--blue-color);
  border-radius: var(--border-radius);
}
