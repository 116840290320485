.reg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: var(--main-page-padding);

  .regForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    width: 100%;
  }

  .fieldLabel, .field, .button {
    max-width: 250px;
    width: 100%;
    margin: var(--gap-slt) 0;
  }

  .link {
    margin: var(--gap-small) 0;
  }

  .fieldLabel {
    .field {
      margin: 0;
    }

    .fieldError {
      margin-top: var(--gap-slt);
      padding-left: 1em;
    }
  }
}
