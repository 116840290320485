@import './src/styles/field';

.label {
  position: relative;

  .field {
    width: 100% !important;
    margin: 0 !important;
  }

  .fieldError {
    margin-top: var(--gap-slt);
  }
}

.input {
  @extend %field;

  &::placeholder {
    @extend %placeholder;
  }

  &:hover, &:focus {
    border-color: var(--blue-color);
  }

  &.error {
    border-color: var(--red-color);
  }
}
